/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as o } from "../chunks/tslib.es6.js";
import r from "../Color.js";
import { JSONMap as s } from "../core/jsonMap.js";
import { clone as e } from "../core/lang.js";
import { property as t } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/Logger.js";
import { enumeration as i } from "../core/accessorSupport/decorators/enumeration.js";
import { subclass as l } from "../core/accessorSupport/decorators/subclass.js";
import a from "./FillSymbol.js";
import n from "./SimpleLineSymbol.js";
var p;
const c = new s({
  esriSFSSolid: "solid",
  esriSFSNull: "none",
  esriSFSHorizontal: "horizontal",
  esriSFSVertical: "vertical",
  esriSFSForwardDiagonal: "forward-diagonal",
  esriSFSBackwardDiagonal: "backward-diagonal",
  esriSFSCross: "cross",
  esriSFSDiagonalCross: "diagonal-cross"
});
let m = p = class extends a {
  constructor(...o) {
    super(...o), this.color = new r([0, 0, 0, .25]), this.outline = new n(), this.type = "simple-fill", this.style = "solid";
  }
  normalizeCtorArgs(o, r, s) {
    if (o && "string" != typeof o) return o;
    const e = {};
    return o && (e.style = o), r && (e.outline = r), s && (e.color = s), e;
  }
  clone() {
    return new p({
      color: e(this.color),
      outline: this.outline && this.outline.clone(),
      style: this.style
    });
  }
  hash() {
    return `${super.hash()}${this.style}.${this.color && this.color.hash()}`;
  }
};
o([t()], m.prototype, "color", void 0), o([t()], m.prototype, "outline", void 0), o([i({
  esriSFS: "simple-fill"
}, {
  readOnly: !0
})], m.prototype, "type", void 0), o([t({
  type: c.apiValues,
  json: {
    read: c.read,
    write: c.write
  }
})], m.prototype, "style", void 0), m = p = o([l("esri.symbols.SimpleFillSymbol")], m);
const S = m;
export { S as default };